import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import * as jzip from "jszip";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
import { Button, FormGroup } from "reactstrap";
import "./styleConsult.css";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Spinner from "../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import { localeEs } from "../locale/ag-grid-es";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Select from "react-select";
import BreadCrum from "../breadCrum/breadCrum";
import withTokenCheck from "../withTokenCheck";

window.JSZip = jzip;

const AppReport = ({ token }) => {
    const [form, setForm] = useState([]);
    const currentDate = dayjs();
    const [dateStart, setDateStart] = useState(currentDate);
    const [dateEnd, setDateEnd] = useState(currentDate);
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const tableRef = useRef(null);
    const etiquetas = ["Lossman Inicio", "Reportes", "Reporte General"];
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (token) {
            axios
                .get("/edicion-formulario/consulta/reporte", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const emptyOption = { value: null, label: "Seleccionar o Dejar Vacío" };

    const numberCaseoptions = [
        emptyOption,
        ...numberCase.map((option) => ({
            value: option.id,
            label: option.NumeroCaso ?? option.NumeroCaso,
        })),
    ];

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };

    const handleConsult = (event) => {
        event.preventDefault();
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
            FormularioId: selectedCase2,
        };
        if (token) {
            setLoading(true);
            axios
                .get("/formulario", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    const filteredData = response.data.map(item => {
                        // Si existe el campo 'headers', lo quitamos
                        const { headers, ...rest } = item;
                        return rest;  // Retorna el objeto sin el campo 'headers'
                    });
                    setForm(filteredData);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Descargar PDF",
            cellRenderer: (params) => (
                <Button color="secondary" onClick={() => editRow(params.data)}>
                    Descargar PDF
                </Button>
            ),
        },
        {
            headerName: "Nombre del paciente",
            field: "paciente.Nombre",
        },
        { headerName: "Numero de caso", field: "NumeroCaso" },
        {
            headerName: "Habilitado",
            field: "paciente.Habilitado",
        },
        {
            headerName: "Motivo deshabilitación",
            field: "paciente.MotivoDeshabilitacion",
        },
        {
            headerName: "Autorizado",
            field: "Autorizado",
        },
        {
            headerName: "¿Hospitalizado?",
            field: "Hospitalizado",
        },
        {
            headerName: "Check-Out",
            field: "Checkout",
        },
        {
            headerName: "Hogar Asignado",
            field: "hogar.Nombre",
        },
        {
            headerName: "¿Habitación asignada?",
            field: "asignacion_existente",
        },
        {
            headerName: "¿Facturado?",
            field: "No",
            cellRenderer: () => {
                const facturado = "No";
                return facturado;
            },
        },
        {
            headerName: "Numero de contacto",
            field: "paciente.TelContacto",
        },
        {
            headerName: "Tipo de documento",
            field: "paciente.tipo_documento.Texto",
        },
        {
            headerName: "Numero de documento",
            field: "paciente.NumeroIdent",
        },
        {
            headerName: "Ciudad de origen",
            field: "paciente.ciudad.Texto",
        },
        {
            headerName: "Fecha de solicitud",
            field: "FechaSolicitud",
        },
        {
            headerName: "Fecha de inicio",
            field: "FechaInicial",
        },
        {
            headerName: "Fecha de fin",
            field: "FechaFinal",
        },
        {
            headerName: "Días de hospedaje",
            field: "DiasHospedaje",
        },
        {
            headerName: "Numero mipres",
            field: "NumeroMIPRES",
        },
        {
            headerName: "Tipo de solicitud",
            field: "tipo_solicitud.Texto",
        },
        {
            headerName: "Tipo de servicio",
            field: "descripcion.Texto",
        },
        {
            headerName: "codigo interno del servicio",
            field: "codigo_servicios.CodigoServicio",
        },
        {
            headerName: "Codigo de servicio",
            field: "CodigoServicio",
        },
        {
            headerName: "Descripción",
            field: "Descripcion",
        },
        {
            headerName: "Primer Servicio",
            valueGetter: (params) => {
                const primerServicio = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[0]
                        ? params.data.servicio_maestro.servicio_detalle[0]
                              .tipo_servicio
                            ? params.data.servicio_maestro.servicio_detalle[0]
                                  .tipo_servicio.Texto
                            : ""
                        : ""
                    : " ";
                return primerServicio;
            },
            cellRenderer: (params) => {
                const primerServicio = params.value ? params.value : "";
                return primerServicio;
            },
        },
        {
            headerName: "Fecha Primer Servicio",
            valueGetter: (params) => {
                const fechaPrimerServicio = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[0]
                        ? params.data.servicio_maestro.servicio_detalle[0]
                              .FechaConsulta
                            ? params.data.servicio_maestro.servicio_detalle[0]
                                  .FechaConsulta
                            : ""
                        : ""
                    : "";
                return fechaPrimerServicio;
            },
            cellRenderer: (params) => {
                const fechaPrimerServicio = params.value ? params.value : "";
                return fechaPrimerServicio;
            },
        },
        {
            headerName: "Ips Primer Servicio",
            valueGetter: (params) => {
                const Ips1 = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[0]
                        ? params.data.servicio_maestro.servicio_detalle[0].ips
                            ? params.data.servicio_maestro.servicio_detalle[0]
                                  .ips.Texto
                            : ""
                        : ""
                    : "";
                return Ips1;
            },
            cellRenderer: (params) => {
                const Ips1 = params.value ? params.value : "";
                return Ips1;
            },
        },
        {
            headerName: "Segundo Servicio",
            valueGetter: (params) => {
                const segundoServicio = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[1]
                        ? params.data.servicio_maestro.servicio_detalle[1]
                              .tipo_servicio
                            ? params.data.servicio_maestro.servicio_detalle[1]
                                  .tipo_servicio.Texto
                            : ""
                        : ""
                    : " ";
                return segundoServicio;
            },
            cellRenderer: (params) => {
                const segundoServicio = params.value ? params.value : "";
                return segundoServicio;
            },
        },
        {
            headerName: "Fecha segundo Servicio",
            valueGetter: (params) => {
                const segundoServicioFecha = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[1]
                        ? params.data.servicio_maestro.servicio_detalle[1]
                              .FechaConsulta
                            ? params.data.servicio_maestro.servicio_detalle[1]
                                  .FechaConsulta
                            : ""
                        : ""
                    : "";
                return segundoServicioFecha;
            },
            cellRenderer: (params) => {
                const segundoServicioFecha = params.value ? params.value : "";
                return segundoServicioFecha;
            },
        },
        {
            headerName: "Ips segundo Servicio",
            valueGetter: (params) => {
                const Ips2 = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[1]
                        ? params.data.servicio_maestro.servicio_detalle[1].ips
                            ? params.data.servicio_maestro.servicio_detalle[1]
                                  .ips.Texto
                            : ""
                        : ""
                    : "";
                return Ips2;
            },
            cellRenderer: (params) => {
                const Ips2 = params.value ? params.value : "";
                return Ips2;
            },
        },
        {
            headerName: "Tercer Servicio",
            valueGetter: (params) => {
                const tercerServicio = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[2]
                        ? params.data.servicio_maestro.servicio_detalle[2]
                              .tipo_servicio
                            ? params.data.servicio_maestro.servicio_detalle[2]
                                  .tipo_servicio.Texto
                            : ""
                        : ""
                    : " ";
                return tercerServicio;
            },
            cellRenderer: (params) => {
                const tercerServicio = params.value ? params.value : "";
                return tercerServicio;
            },
        },
        {
            headerName: "Fecha Tercer Servicio",
            valueGetter: (params) => {
                const tercerServicioFecha = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[2]
                        ? params.data.servicio_maestro.servicio_detalle[2]
                              .FechaConsulta
                            ? params.data.servicio_maestro.servicio_detalle[2]
                                  .FechaConsulta
                            : ""
                        : ""
                    : "";
                return tercerServicioFecha;
            },
            cellRenderer: (params) => {
                const tercerServicioFecha = params.value ? params.value : "";
                return tercerServicioFecha;
            },
        },
        {
            headerName: "Ips Tercer Servicio",
            valueGetter: (params) => {
                const Ips3 = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[2]
                        ? params.data.servicio_maestro.servicio_detalle[2].ips
                            ? params.data.servicio_maestro.servicio_detalle[2]
                                  .ips.Texto
                            : ""
                        : ""
                    : "";
                return Ips3;
            },
            cellRenderer: (params) => {
                const Ips3 = params.value ? params.value : "";
                return Ips3;
            },
        },
        {
            headerName: "Cuarto Servicio",
            valueGetter: (params) => {
                const cuartoServicio = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[3]
                        ? params.data.servicio_maestro.servicio_detalle[3]
                              .tipo_servicio
                            ? params.data.servicio_maestro.servicio_detalle[3]
                                  .tipo_servicio.Texto
                            : ""
                        : ""
                    : " ";
                return cuartoServicio;
            },
            cellRenderer: (params) => {
                const cuartoServicio = params.value ? params.value : "";
                return cuartoServicio;
            },
        },
        {
            headerName: "Fecha cuarto Servicio",
            valueGetter: (params) => {
                const cuartoServicioFecha = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[3]
                        ? params.data.servicio_maestro.servicio_detalle[3]
                              .FechaConsulta
                            ? params.data.servicio_maestro.servicio_detalle[3]
                                  .FechaConsulta
                            : ""
                        : ""
                    : "";
                return cuartoServicioFecha;
            },
            cellRenderer: (params) => {
                const cuartoServicioFecha = params.value ? params.value : "";
                return cuartoServicioFecha;
            },
        },
        {
            headerName: "Ips cuarto Servicio",
            valueGetter: (params) => {
                const Ips4 = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[3]
                        ? params.data.servicio_maestro.servicio_detalle[3].ips
                            ? params.data.servicio_maestro.servicio_detalle[3]
                                  .ips.Texto
                            : ""
                        : ""
                    : "";
                return Ips4;
            },
            cellRenderer: (params) => {
                const Ips4 = params.value ? params.value : "";
                return Ips4;
            },
        },
        {
            headerName: "Quinto Servicio",
            valueGetter: (params) => {
                const quintoServicio = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[4]
                        ? params.data.servicio_maestro.servicio_detalle[4]
                              .tipo_servicio
                            ? params.data.servicio_maestro.servicio_detalle[4]
                                  .tipo_servicio.Texto
                            : ""
                        : ""
                    : " ";
                return quintoServicio;
            },
            cellRenderer: (params) => {
                const quintoServicio = params.value ? params.value : "";
                return quintoServicio;
            },
        },
        {
            headerName: "Fecha quinto Servicio",
            valueGetter: (params) => {
                const quintoServicioFecha = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[4]
                        ? params.data.servicio_maestro.servicio_detalle[4]
                              .FechaConsulta
                            ? params.data.servicio_maestro.servicio_detalle[4]
                                  .FechaConsulta
                            : ""
                        : ""
                    : "";
                return quintoServicioFecha;
            },
            cellRenderer: (params) => {
                const quintoServicioFecha = params.value ? params.value : "";
                return quintoServicioFecha;
            },
        },
        {
            headerName: "Ips quinto Servicio",
            valueGetter: (params) => {
                const Ips5 = params.data.servicio_maestro
                    ? params.data.servicio_maestro.servicio_detalle[4]
                        ? params.data.servicio_maestro.servicio_detalle[4].ips
                            ? params.data.servicio_maestro.servicio_detalle[4]
                                  .ips.Texto
                            : ""
                        : ""
                    : "";
                return Ips5;
            },
            cellRenderer: (params) => {
                const Ips5 = params.value ? params.value : "";
                return Ips5;
            },
        },
        {
            headerName: "Primer acompañante",
            valueGetter: (params) => {
                const primerAcompanante = params.data.acompanantes
                    ? params.data.acompanantes[0]
                        ? params.data.acompanantes[0].Nombre
                        : ""
                    : "";
                return primerAcompanante;
            },
            cellRenderer: (params) => {
                const primerAcompanante = params.value ? params.value : "";
                return primerAcompanante;
            },
        },
        {
            headerName: "Tipo acompañante",
            valueGetter: (params) => {
                const tipoAcompanante = params.data.acompanantes
                    ? params.data.acompanantes[0]
                        ? params.data.acompanantes[0].tipo_acompanante
                            ? params.data.acompanantes[0].tipo_acompanante.Texto
                            : ""
                        : ""
                    : "";
                return tipoAcompanante;
            },
            cellRenderer: (params) => {
                const tipoAcompanante = params.value ? params.value : "";
                return tipoAcompanante;
            },
        },
        {
            headerName: "Identificación",
            valueGetter: (params) => {
                const identificacionAcompanante = params.data.acompanantes
                    ? params.data.acompanantes[0]
                        ? params.data.acompanantes[0].NumeroIdent
                        : ""
                    : "";
                return identificacionAcompanante;
            },
            cellRenderer: (params) => {
                const identificacionAcompanante = params.value
                    ? params.value
                    : "";
                return identificacionAcompanante;
            },
        },
        {
            headerName: "Segundo acompañante",
            valueGetter: (params) => {
                const primerAcompanante = params.data.acompanantes
                    ? params.data.acompanantes[1]
                        ? params.data.acompanantes[1].Nombre
                        : ""
                    : "";
                return primerAcompanante;
            },
            cellRenderer: (params) => {
                const primerAcompanante = params.value ? params.value : "";
                return primerAcompanante;
            },
        },
        {
            headerName: "Tipo acompañante",
            valueGetter: (params) => {
                const tipoAcompanante = params.data.acompanantes
                    ? params.data.acompanantes[1]
                        ? params.data.acompanantes[1].tipo_acompanante
                            ? params.data.acompanantes[1].tipo_acompanante.Texto
                            : ""
                        : ""
                    : "";
                return tipoAcompanante;
            },
            cellRenderer: (params) => {
                const tipoAcompanante = params.value ? params.value : "";
                return tipoAcompanante;
            },
        },
        {
            headerName: "Identificación",
            valueGetter: (params) => {
                const identificacionAcompanante = params.data.acompanantes
                    ? params.data.acompanantes[1]
                        ? params.data.acompanantes[1].NumeroIdent
                        : ""
                    : "";
                return identificacionAcompanante;
            },
            cellRenderer: (params) => {
                const identificacionAcompanante = params.value
                    ? params.value
                    : "";
                return identificacionAcompanante;
            },
        },

        {
            headerName: "Observaciones",
            field: "Observaciones",
        },
        {
            headerName: "Usuario Creación",
            field: "created_by",
            cellRenderer: (params) => {
                const userCreate = params.data.created_by
                    ? params.data.created_by.name
                    : "";
                return userCreate;
            },
        },
        {
            headerName: "Fecha Creación",
            field: "created_at",
            cellRenderer: (params) => {
                const dateCreate = params.data.created_at;
                return dateCreate;
            },
        },
        {
            headerName: "Usuario Actualización",
            field: "updated_by",
            cellRenderer: (params) => {
                const userUpdate = params.data.updated_by
                    ? params.data.updated_by.name
                    : "";
                return userUpdate;
            },
        },
        {
            headerName: "Fecha Actualización",
            field: "updated_at",
            cellRenderer: (params) => {
                const dateCreate = params.data.updated_at;
                return dateCreate;
            },
        },
        {
            headerName: "Usuario Eliminación",
            field: "deleted_by",
            cellRenderer: (params) => {
                const userDeleted = params.data.deleted_by
                    ? params.data.deleted_by.name
                    : "";
                return userDeleted;
            },
        },
        {
            headerName: "Fecha Eliminación",
            field: "deleted_at",
            cellRenderer: (params) => {
                const dateDeleted = params.data.deleted_at;
                return dateDeleted;
            },
        },
    ];

    function editRow(content) {
        const id = content.id;
        if (token) {
            axios
                .get(`/formulario/descargarPDF/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob",
                })
                .then((response) => {
                    if (response.status == 200) {
                        // Obtener el nombre del archivo del encabezado Content-Disposition
                        const contentDisposition = response.headers.get(
                            "Content-Disposition"
                        );
                        const fileNameMatch =
                            contentDisposition &&
                            contentDisposition.match(/filename="(.+)"/);
                        const fileName = fileNameMatch
                            ? fileNameMatch[1]
                            : "documento.pdf";

                        // Crear un Blob a partir del contenido del archivo
                        const blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        // Crear un enlace temporal para descargar el archivo
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "No se encontro información del PDF",
                        showCloseButton: true,
                    });
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>Reporte General</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}

                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>

                <div
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        marginTop: "2rem",
                    }}
                >
                    <label htmlFor="servicioId">
                        Seleccione el numero de caso que desea consultar
                    </label>
                    <FormGroup id="FormEdit">
                        <Select
                            id="SelectEdit"
                            options={numberCaseoptions}
                            value={selectedCase}
                            onChange={handleNumberCaseChange}
                        ></Select>
                    </FormGroup>
                </div>
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container mt-5">
                        <label htmlFor="servicioId">
                            Ingrese el rango de fechas que desea consultar
                        </label>
                        <div className="ConsultReport">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Desde"
                                        id="dateStart"
                                        value={dateStart}
                                        onChange={setDateStart}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Hasta"
                                        value={dateEnd}
                                        onChange={setDateEnd}
                                    />
                                </Stack>
                            </LocalizationProvider>

                            <Button
                                id="buttonConsult"
                                className="mb-3"
                                color="secondary"
                                onClick={handleConsult}
                            >
                                Consultar
                            </Button>
                        </div>
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={form}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection={"multiple"}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReport);
