import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import BedSelection from "../../ComponentsUse/Select/selectBed";
import { TextField } from "@material-ui/core";

const ModalCreate = ({ isOpen, toggle, title, formId, token, infoModal, updateTable }) => {

  const [numeroDiasAmpliacion, setNumeroDiasAmpliacion] = useState("");
  const [nuevoFormulario, setNuevoFormulario] = useState("");
  const [fechaInicial, setFechaInicial] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [numeroMipres, setNumeroMipres] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  console.log(infoModal, 'información modal')
  const handleDiasAmpliacion = (event) => {
    setNumeroDiasAmpliacion
    (event.target.value);
  };

  const handleNumeroFormulario = (event) => {
    setNuevoFormulario
    (event.target.value);
  };

  const handleNumeroMipres = (event) => {
    setNumeroMipres
    (event.target.value);
  };

  useEffect(() => {
    // Si infoModal tiene FechaFinal válida, calcula la nueva fecha inicial
    if (infoModal?.FechaFinal) {
      const nuevaFechaInicial = new Date(infoModal.FechaFinal);
      nuevaFechaInicial.setDate(nuevaFechaInicial.getDate() + 1);
      setFechaInicial(nuevaFechaInicial.toISOString().split("T")[0]);
    }
  }, [infoModal]);


  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos del paciente y de los acompañantes
    axios
      .post(`ampliacion/${formId}`, {
        NumeroDiasAmpliacion: numeroDiasAmpliacion,
        NumeroFormulario: nuevoFormulario,
        NumeroMipres: numeroMipres,
        FechaInicial: fechaInicial,
        FechaFinal: fechaFinal,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "se ha ampliado la estadia del usuario con exito",
          showCloseButton: true,
        }).then(() => {
          toggle();
          updateTable();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al ampliar la estadia.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
      <FormGroup>
          <TextField
              label="Nuevo número de formulario"
              className="form-control "
              type="Number"
              name="numeroFormulario"
              value={nuevoFormulario}
              onChange={handleNumeroFormulario}
          />
      </FormGroup>
      <FormGroup>
          <TextField
              label="Nuevo número Mipres"
              className="form-control "
              type="text"
              name="numeroMipres"
              value={numeroMipres}
              onChange={handleNumeroMipres}
          />
      </FormGroup>
        <FormGroup>
          <TextField
              label="Cantidad de días de ampliación"
              className="form-control "
              type="Number"
              name="diasAmpliacion"
              value={numeroDiasAmpliacion}
              onChange={handleDiasAmpliacion}
          />
      </FormGroup>
      <FormGroup>
        <label>Fecha Inicial:</label>
        <input
            className="form-control"
            name="fechaInicial"
            type="date"
            onChange={(event) => setFechaInicial(event.target.value)}
            value={ fechaInicial }
            disabled
        />
    </FormGroup>
    <FormGroup>
        <label>Fecha Final:</label>
        <input
            className="form-control"
            name="fechaInicial"
            type="date"
            onChange={(event) => setFechaFinal
              (event.target.value)}
            value={ fechaFinal }
        />
    </FormGroup>
      
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
